import React, { Component } from 'react';

import { Cards, Chart, CountryPicker, Footer } from './components';
import styles from './App.module.css';
import { fetchData } from './api';

import coronaImage from './images/covid-19.png'

class App extends Component {
    
    state = {
        data: {},
        country: 'global',
    };

    async componentDidMount () {
        const fetchedData = await fetchData(this.state.country);
        
        this.setState({
            data: fetchedData,
            country: 'global',
        });
    }

    handleCountryChange = async (country) => {
        const fetchedData = await fetchData(country);
        //console.log(fetchedData);
        this.setState({
            data: fetchedData,
            country: country,
        });
    }

    render () {
        const { data, country } = this.state;
        //console.log(data);

        return (
            <div className={styles.container}>
                <img className={styles.image} src={coronaImage} alt="COVID-19" />
                <Cards data={data} />
                <CountryPicker handleCountryChange={this.handleCountryChange} />
                <Chart data={data} country={country} />
                <Footer />
            </div>
        );
    }
}

export default App;
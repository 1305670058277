import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { fetchDailyData } from '../../api';
import { Line, Bar } from 'react-chartjs-2';
import 'chartjs-plugin-datalabels';

import styles from './Chart.module.css';


const Chart = ({ data: { confirmed, recovered, deaths }, country }) => {
    const [ dailyData, setDailyData ] = useState([]);

    const infectectColor = 'rgba(196, 149, 33, 0.5)';
    const recoveredColor = 'rgba(33, 194, 196, 0.5)';
    const deathsColor = 'rgba(144, 12, 63, 0.5)';

    useEffect(() => {
        const fetchAPI = async () => {
            setDailyData(await fetchDailyData());
        }
        fetchAPI();
    }, []);

    const lineChart = (
        dailyData.length ? (
            <Line
                data={{
                    labels: dailyData.map(({ date }) => date),
                    datasets: [{
                        data: dailyData.map(({ confirmed }) => confirmed),
                        label: 'Infected',
                        borderColor: infectectColor,
                        backgroundColor: infectectColor,
                        fill: true,
                    }, {
                        data: dailyData.map(({ deaths }) => deaths),
                        label: 'Deaths',
                        borderColor: deathsColor,
                        backgroundColor: deathsColor,
                        fill: true,
                    }]
                }}
                options = {{
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback(value) {
                                    // you can add your own method here (just an example)
                                    return Number(value).toLocaleString('en')
                                }
                            }
                        }]
                    },
                    // Hide datalabels for lineChart
                    plugins: {
                        datalabels: {
                            display: false,
                        }
                    },
                }}
            />
        ) : null
    );

    const barChart = (
        confirmed ? (
            <Bar 
                data = {{
                    labels: ['Infected', 'Recovered', 'Deaths'],
                    datasets: [{
                        label: 'People',
                        backgroundColor: [infectectColor, recoveredColor, deathsColor],
                        data: [confirmed.value, recovered.value, deaths.value]
                    }]
                }}
                options={{
                    legend: { display: false },
                    title: { display: true, text:`Current status in ${country === "global" ? "the World" : country}` },
                    scales: {
                        yAxes: [{
                            ticks: {
                                callback(value) {
                                    // you can add your own method here (just an example)
                                    return Number(value).toLocaleString('en')
                                }
                            }
                        }]
                    },
                }}
            />

        ) : null
    );

    return (
        <div className={styles.container}>
            {/*
            Initially country is global and later it is changeable.
            If country is undefined or global, show the lineChart and barChart.
            When country is defined and country is not global, show only barChart.
            If lineChart is formed, provide a gap.
            */}
            <div className={styles.lineChart}>{(!country || country==="global") ? lineChart : null}</div>
            <div className={styles.barChart}>{barChart}</div>
            
        </div>
    )
};

Chart.propTypes = {
    data: PropTypes.object.isRequired,
    country: PropTypes.string,
};

export default Chart;
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';
import CountUp from 'react-countup';
import cx from 'classnames';

import styles from './Cards.module.css';
import loadingGif from '../../images/loading.gif'

const Cards = (props) => {
    const { data: { confirmed, recovered, deaths, lastUpdate } } = props;
    
    if(!confirmed) {
        // Until data is fetched, show this loading gif
        return <img className={styles.loading} src={loadingGif} alt="loading" />;
    }

    return (
        <div className={styles.container}>
            <Grid container spacing={3} justify="center">
                <Grid
                    item
                    component={Card}
                    xs={12}
                    md={3}
                    className={cx(styles.card, styles.infected)}
                >
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Infected
                        </Typography>

                        <Typography variant="h5">
                            <CountUp
                                start={0}
                                end={confirmed.value}
                                separator=","
                            />
                        </Typography>
                        
                        <Typography color="textSecondary">
                            {new Date(lastUpdate).toDateString()}
                        </Typography>

                        <Typography variant="body2">
                            Number of active cases of COVID-19
                        </Typography>
                    </CardContent>
                </Grid>

                <Grid
                    item
                    component={Card}
                    xs={12}
                    md={3}
                    className={cx(styles.card, styles.recovered)}
                >
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Recovered
                        </Typography>

                        <Typography variant="h5">
                            <CountUp
                                start={0}
                                end={recovered.value}
                                separator=","
                            />
                        </Typography>
                        
                        <Typography color="textSecondary">
                            {new Date(lastUpdate).toDateString()}
                        </Typography>

                        <Typography variant="body2">
                            Number of recoveries from COVID-19
                        </Typography>
                    </CardContent>
                </Grid>

                <Grid
                    item
                    component={Card}
                    xs={12}
                    md={3}
                    className={cx(styles.card, styles.deaths)}
                >
                    <CardContent>
                        <Typography color="textSecondary" gutterBottom>
                            Deaths
                        </Typography>

                        <Typography variant="h5">
                            <CountUp
                                start={0}
                                end={deaths.value}
                                separator=","
                            />
                        </Typography>
                        
                        <Typography color="textSecondary">
                            {new Date(lastUpdate).toDateString()}
                        </Typography>

                        <Typography variant="body2">
                            Number of deaths caused by COVID-19
                        </Typography>
                    </CardContent>
                </Grid>
            </Grid>
        </div>
    );
};

Cards.propTypes = {
    data: PropTypes.object.isRequired,
};

export default Cards;
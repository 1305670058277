import React from 'react';
import { Typography } from '@material-ui/core';
import styles from './Footer.module.css';

const Footer = () => {
    const createAnchor = (link, title) => {
        return <a href={link} target="_blank" rel="noopener noreferrer">{title}</a>
    }

    const sourceAPI = "https://github.com/CSSEGISandData/COVID-19";
    const linkedInUrl = "https://www.linkedin.com/in/himeldas";

    return (
        <div className={styles.container}>
            <Typography color="textSecondary">
                Source: {createAnchor(sourceAPI, "Johns Hopkins CSSE")}
            </Typography>
            <Typography color="textSecondary">
                Developed by {createAnchor(linkedInUrl, "Himel Das")} for People
            </Typography>
        </div>
    )
}

export default Footer;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { NativeSelect, FormControl } from '@material-ui/core';
import styles from './CountryPicker.module.css';

import { fetchCountries } from '../../api';

const CountryPicker = ({ handleCountryChange }) => {
    const [ fetchedCountries, setfetchedCountries ] = useState([]);
    useEffect(() => {
        const fetchAPI = async () => {
            setfetchedCountries(await fetchCountries()); // countries is coming from the api
        }

        fetchAPI();
    }, [setfetchedCountries]);

    //console.log(fetchedCountries);

    return (
        <FormControl className={styles.formControl}>
            <NativeSelect
                defaultValue=""
                onChange={(e) => handleCountryChange(e.target.value)}
            >
                <option value="global">Global</option>
                {fetchedCountries.map((country, i) => (
                    <option key={i} value={country}>{country}</option>
                ))}
            </NativeSelect>
        </FormControl>
    );
};

CountryPicker.propTypes = {
    handleCountryChange: PropTypes.func.isRequired,
};

export default CountryPicker;